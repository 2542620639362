<template>
  <div>
    <div v-if="isLoading"><ui-preloader /></div>
    <div v-else-if="isLoaded && review">
      <div class="row row--flow-vertical">
        <div class="col col--flow-vertical ui-node-overview__heading">
          <dl>
            <dt class="label">
              {{ $t("graph.events.fields.title") }}
            </dt>
            <dd class="txt--large txt--bold">
              {{ review.title }}
            </dd>
          </dl>
        </div>
        <div class="row row--wrap">
          <div :class="`col col--${p.size || 50}`" v-for="p in properties" v-bind:key="p.label">
            <dl>
              <dt>{{ p.label }}</dt>
              <dd>{{ p.value }}</dd>
            </dl>
          </div>
        </div>
        <div class="col col--gap" v-if="type == 'overview'">
          <ui-button
            appearance="primary"
            span="100"
            component="router-link"
            target="_blank"
            :to="reviewLocation"
          >
            {{ $t("graph.events.view-details") }}
          </ui-button>
        </div>
        <div class="col" v-if="type == 'overview'">
          <ui-button
            appearance="primary"
            span="100"
            component="router-link"
            target="_blank"
            :to="profileLocation"
          >
            {{ $t("graph.events.view-profile") }}
          </ui-button>
        </div>
      </div>
    </div>
    <div v-else-if="loadingError">
      {{ $t("graph.events.loadingError") }}
    </div>
  </div>
</template>

<script lang="ts">
import { IGraphNode } from "@pasabi/ui-blossom/types/interfaces/graph";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UiButton, UiPreloader } from "@pasabi/ui-components";
import { RawLocation } from "vue-router";
import { EventDetailsStore } from "../../store/modules/EventDetails";

const REVIEW_TRUCATE_BY_TYPE = {
  overlay: 150,
  overview: 250,
};

@Component({
  components: {
    UiButton,
    UiPreloader,
  },
})
export default class ReviewGraphNode extends Vue {
  store = EventDetailsStore;

  @Prop({ required: true }) node!: IGraphNode;
  @Prop({ required: true }) type!: "overlay" | "overview";

  get reviewId() {
    return this.node.properties.display_id;
  }

  @Watch("reviewId", { immediate: true })
  reviewIdChanged() {
    if (this.reviewId) {
      this.loadData();
    }
  }

  loadData() {
    this.store.fetchItem({ id: this.reviewId });
  }

  get properties(): { label: string; value: string | number; size?: number }[] {
    if (this.review) {
      return [
        {
          label: this.$t("graph.events.fields.id").toString(),
          value: this.review.external_id,
          size: 100,
        },
        {
          label: this.$t("graph.events.fields.content").toString(),
          value:
            this.review.text.length > 250
              ? `${this.review.text.substring(0, REVIEW_TRUCATE_BY_TYPE[this.type] || 250)}...`
              : this.review.text,
          size: 100,
        },
        {
          label: this.$t("graph.events.fields.spamScam").toString(),
          value: this.review.spam?.toString(),
        },
        {
          label: this.$t("graph.events.fields.reviewScore").toString(),
          value: this.review.score,
        },
        {
          label: "Invitation",
          value: this.review.invitation_method,
        },
      ].filter(({ value }) => value != undefined);
    }
    return [];
  }

  get isLoading() {
    return this.store.isLoading({ id: this.reviewId });
  }

  get isLoaded() {
    return this.store.isLoaded({ id: this.reviewId });
  }

  get loadingError() {
    return this.store.loadingError({ id: this.reviewId });
  }

  get review() {
    return this.store.item(this.reviewId);
  }

  get reviewLocation(): RawLocation {
    return {
      name: "event>details",
      params: {
        ...this.$router.currentRoute.params,
        id: this.reviewId,
      },
    };
  }

  get profileLocation(): RawLocation | undefined {
    if (this.review) {
      return {
        name: "actor>details",
        params: {
          ...this.$router.currentRoute.params,
          id: this.review.subject_display_id,
        },
      };
    }
    return undefined;
  }
}
</script>
